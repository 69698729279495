<template>
    <div>
        <div class="row">
            <div class="col-md-12 new-mails-table">
                <KTCodePreview class="new-mails" v-bind:title="'Mail-uri noi '">
                    <template v-slot:preview>
                        <v-simple-table fixed-header v-if="newMails.length > 0">
                            <template v-slot:default>
                                <div class="card card-custom card-stretch">
                                    <div class="card-body table-responsive px-0">
                                        <!--begin::Items-->
                                        <div
                                            class="list mail-table list-hover min-w-500px"
                                        >
                                            <NewItem
                                                v-for="mail in newMails"
                                                :key="mail.id"
                                                :mail="mail"
                                            >
                                            </NewItem>

                                            <p
                                                v-if="
                                                    totalNewMails > newMails.length &&
                                                    newMails.length >= 200
                                                "
                                                class="no-mails text-primary"
                                            >
                                                ({{ newMails.length }} din
                                                {{ totalNewMails }}) incarcate<br />
                                                <span class="text-danger"
                                                    >Mai rezolvă din mailurile de sus,
                                                    pentru a putea afisa și alte mailuri
                                                    noi</span
                                                >
                                            </p>
                                            <p
                                                v-else-if="
                                                    totalNewMails > newMails.length &&
                                                    newMails.length
                                                "
                                                class="no-mails text-info"
                                            >
                                                <span
                                                    class="text-primary"
                                                    v-if="
                                                        totalNewMails - newMails.length >
                                                        1
                                                    "
                                                    >{{
                                                        totalNewMails - newMails.length
                                                    }}
                                                    mailuri</span
                                                >
                                                <span class="text-primary" v-else
                                                    >1 mail</span
                                                >
                                                <br /><span
                                                    v-if="
                                                        totalNewMails - newMails.length >
                                                        1
                                                    "
                                                    >sunt asignate altor utilizatori</span
                                                >
                                                <span v-else>
                                                    este asignat altui utilizator</span
                                                >
                                                din acest departament,<br />
                                                <span class="text-muted"
                                                    >la care au acces doar manageri sau
                                                    utilizatorul al carui mail este in
                                                    aceasta lista</span
                                                >
                                            </p>
                                            <p v-else class="no-mails text-primary">
                                                THE END...
                                            </p>
                                        </div>
                                        <!--end::Items-->
                                    </div>
                                </div>
                                <pre></pre>
                            </template>
                        </v-simple-table>
                        <p v-if="!loadNewMails" class="no-mails text-primary">
                            Se incarca conversatiile...
                            <b-icon
                                icon="arrow-clockwise"
                                animation="spin-pulse"
                            ></b-icon>
                        </p>
                        <p class="no-mails" v-if="loadNewMails && !newMails.length">
                            Nu sunt mailuri disponibile.
                        </p>
                    </template>
                    <template v-slot:buttons>
                        <b-button-toolbar
                            class=""
                            aria-label="Toolbar with button groups and dropdown menu"
                        >
                            <b-button-group class="mx-1 btn-group">
                                <b-button
                                    disabled
                                    style="margin-right: 0.25rem !important"
                                >
                                    {{ totalNewMails }}
                                    <b-icon
                                        v-if="!loadNewMails"
                                        icon="arrow-clockwise"
                                        animation="spin-pulse"
                                    ></b-icon>
                                </b-button>
                                <b-button
                                    :disabled="selectedMails.length === 0"
                                    v-b-modal.modal-pase
                                    @click="modalOpen()"
                                    class="pass-mail-btn"
                                    ><img
                                        src="@/assets/images/ball.png"
                                        class="pass-ball"
                                    />
                                    Paseaza</b-button
                                >
                                <SpamAllButton
                                    :disabled="selectedMails.length === 0"
                                    variant="default"
                                    :selected-mails="selectedMails"
                                />
                                <b-button
                                    :disabled="selectedMails.length === 0"
                                    class="pass-mail-btn mx-1"
                                    @click="closedMailsDialog = true"
                                >
                                    Finalizeaza
                                </b-button>
                                <ClosedMailsDialog
                                    v-model="closedMailsDialog"
                                    :selected-mails="selectedMails"
                                />
                                <b-button-group>
                                    <b-button @click="refreshNewMails()">
                                        <b-icon icon="arrow-clockwise"></b-icon>
                                    </b-button>
                                </b-button-group>
                            </b-button-group>
                        </b-button-toolbar>
                    </template>
                </KTCodePreview>
            </div>
        </div>
    </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import NewItem from "@/view/pages/dashboard/list-items-comp/NewItem.vue";
import SpamAllButton from "@/view/content/buttons/SpamAllButton";
import { mapActions, mapGetters } from "vuex";
import ClosedMailsDialog from "@/view/pages/dashboard/modals/ClosedMailsDialog";

export default {
    data() {
        return {
            selected: [],
            confirmSpam: false,
            mailboxes: "",
            addRule: false,
            timer: null,
            closedMailsDialog: false,
        };
    },
    props: {
        modal: Boolean,
    },
    components: {
        KTCodePreview,
        NewItem,
        SpamAllButton,
        ClosedMailsDialog,
    },
    watch: {},
    methods: {
        modalOpen() {
            if (this.modalP === false) {
                this.openModal;
            }
        },
        mailSpam() {
            let payload = {
                departments: this.allDepartments,
                addRuleForAll: this.addRule,
            };
            this.$store.dispatch("mails/spamMail", payload);
            this.confirmSpam = false;
            // this.$router.push("spam-mails");
        },
        refreshNewMails() {
            let payload = {};
            if (this.$router.history.current.params.user) {
                payload.assignedTo = +this.$router.history.current.params.user;
            } else if (this.user.id && this.user.role !== "admin") {
                payload.assignedTo = this.user.id;
            }

            payload.mailboxId = this.department.id
                ? this.department.id
                : this.departments;
            this.$store.dispatch("mails/getConversations", payload);
        },
        getMailsAddress() {
            let addressEmail = "";
            let mailboxes = "";
            let mails = this.newMails.filter((elem) => {
                let selectedId = false;
                this.selectedMails.forEach((mailId) => {
                    if (mailId === elem.id) selectedId = true;
                });
                if (selectedId && !mailboxes.includes(elem.departament)) {
                    mailboxes = mailboxes + elem.departament + ", ";
                    //   this.mailboxes += elem.department
                }
                if (selectedId && !addressEmail.includes(elem.customer.email))
                    addressEmail += elem.customer.email + ", ";

                return selectedId;
            });
            this.mailboxes = mailboxes.substring(0, mailboxes.length - 2);
            return addressEmail;
        },
        customSetInterval() {
            this.timer = window.setInterval(() => {
                this.refreshNewMails();
                clearInterval(this.timer);
                this.customSetInterval();
            }, 180000);
        },
    },
    computed: {
        ...mapActions("mails", ["getNewMails"]),
        ...mapGetters("mails", ["selectedMails"]),
        ...mapGetters("mails", ["newMails"]),
        ...mapGetters("mails", ["totalNewMails"]),
        // ...mapGetters("mails", ["assigneeMail"]),
        ...mapActions("mails", ["openModal"]),
        ...mapActions("mails", ["spamMail"]),
        ...mapGetters("mails", ["loadNewMails"]),
        ...mapGetters(["user"]),
        modalP() {
            return this.$store.getters["mails/modal"];
        },
        department() {
            return this.$store.getters["departments/department"];
        },
        departments() {
            const departments = this.$store.getters["departments/departments"];
            let departments_ids = [];
            departments.map((department) => {
                departments_ids.push(department.id);
            });
            return departments_ids;
        },
        allDepartments() {
            return this.$store.getters["departments/allDepartments"];
        },
    },
    created() {
        // this.getNewMails;
    },
    mounted() {
        // this.timer = window.setInterval(() => {
        // this.refreshNewMails();
        // }, 180000);
        this.customSetInterval();
    },
    unmount() {
        clearInterval(this.timer);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>

<style>
.spam-dialog .v-label {
    margin-bottom: 0px !important;
}
</style>
