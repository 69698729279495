<template>
    <!-- <v-app> -->
    <div>
        <!--begin::Dashboard-->
        <div v-if="user.id" class="row mail-lists">
            <div class="col-xxl-12">
                <DepartWrapper></DepartWrapper>
            </div>

            <div class="col-xxl-4">
                <!-- <ListWidget9></ListWidget9> -->
            </div>
            <div class="col-xxl-4">
                <!-- <StatsWidget7></StatsWidget7> -->
                <!-- <StatsWidget12></StatsWidget12> -->
            </div>

            <div class="col-xxl-4 order-1 order-xxl-1">
                <!-- <ListWidget1></ListWidget1> -->
            </div>
            <div class="col-xxl-8 order-2 order-xxl-1">
                <!-- <AdvancedTableWidget2></AdvancedTableWidget2> -->
            </div>

            <div
                v-if="(department.isManager || user.role === 'admin') && showUsers"
                class="col-xxl-12 order-1 order-xxl-2"
            >
                <!-- <div class="col-xxl-12 order-1 order-xxl-2"> -->
                <b-alert
                    show
                    variant="light"
                    class="alert-white alert-shadow fade show gutter-b"
                >
                    <Employee></Employee>
                </b-alert>
            </div>

            <div class="modal-wrapper">
                <Modal v-if="config && config.tags"></Modal>
            </div>
            <div class="col-xxl-6 col-xl-6 order-1 order-xxl-2">
                <NewMails></NewMails>
            </div>
            <div class="col-xxl-6 col-xl-6 order-1 order-xxl-2">
                <InProcess></InProcess>
            </div>
            <div class="col-xxl-6 col-xl-6 order-1 order-xxl-2">
                <PendingMails></PendingMails>
            </div>
            <div class="col-xxl-6 col-xl-6 order-1 order-xxl-2">
                <FinalizedMails></FinalizedMails>
            </div>
            <div class="col-xxl-4 order-1 order-xxl-2">
                <!-- <ListWidget4></ListWidget4> -->
            </div>

            <div class="col-xxl-4 order-1 order-xxl-2">
                <!-- <StatsWidget13></StatsWidget13> -->
            </div>
            <div class="col-xxl-8 order-1 order-xxl-2">
                <!-- <AdvancedTableWidget3></AdvancedTableWidget3> -->
            </div>
        </div>
        <!--end::Dashboard-->
    </div>
    <!-- </v-app> -->
</template>

<script>
// import { mapActions } from "vuex";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Employee from "@/view/pages/dashboard/Employee.vue";
import DepartWrapper from "@/view/pages/dashboard/DepartWrapper.vue";
import NewMails from "@/view/pages/dashboard/NewMails2.vue";
import InProcess from "@/view/pages/dashboard/InProcess2.vue";
import PendingMails from "@/view/pages/dashboard/PendingMails2.vue";
import FinalizedMails from "@/view/pages/dashboard/FinalizedMails2.vue";
import Modal from "@/view/pages/dashboard/Modal.vue";
import { mapGetters } from "vuex";

export default {
    name: "dashboard",
    components: {
        Employee,
        NewMails,
        InProcess,
        PendingMails,
        FinalizedMails,
        Modal,
        DepartWrapper,
    },
    data() {
        return {
            prevRoute: null,
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mail Pefoc.ro" }]);
    },
    computed: {
        ...mapGetters(["user"]),
        ...mapGetters("mails", ["config"]),
        department() {
            // console.log(this.$store.getters["departments/department"]);
            return this.$store.getters["departments/department"];
        },
        showUsers() {
            return this.$store.getters["departments/showUsers"];
        },
        // departments() {

        // }
        // ...mapGetters("departments", ["department"]),
    },
    methods: {
        setActiveTab1(event) {
            this.tabIndex = this.setActiveTab(event);
        },
        setActiveTab2(event) {
            this.tabIndex2 = this.setActiveTab(event);
        },
        /**
         * Set current active on click
         * @param event
         */
        setActiveTab(event) {
            // get all tab links
            const tab = event.target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".nav-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set current active tab
            event.target.classList.add("active");

            // set clicked tab index to bootstrap tab
            return parseInt(event.target.getAttribute("data-tab"));
        },
    },

    created() {},
};
</script>
