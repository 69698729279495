<template>
    <div>
        <div class="row">
            <div class="col-md-12 process-mails">
                <KTCodePreview
                    class="inprocess-mails"
                    v-bind:title="'Mail-uri in procesare'"
                >
                    <template v-slot:preview>
                        <v-simple-table
                            fixed-header
                            loading
                            v-if="processMails.length > 0"
                        >
                            <template v-slot:default>
                                <div class="card card-custom card-stretch">
                                    <div class="card-body table-responsive px-0">
                                        <!--begin::Items-->
                                        <div
                                            class="list mail-table list-hover min-w-500px"
                                        >
                                            <ProcessItem
                                                v-for="mail in processMails"
                                                :key="mail.id"
                                                :mail="mail"
                                            >
                                            </ProcessItem>
                                        </div>
                                        <!--end::Items-->
                                    </div>
                                </div>
                            </template>
                        </v-simple-table>
                        <p v-if="!loadInProcessMails" class="no-mails text-primary">
                            Se incarca conversatiile...
                            <b-icon
                                icon="arrow-clockwise"
                                animation="spin-pulse"
                            ></b-icon>
                        </p>
                        <p
                            class="no-mails"
                            v-if="loadInProcessMails && !processMails.length"
                        >
                            Nu sunt mailuri disponibile.
                        </p>
                    </template>
                    <template v-slot:buttons>
                        <b-button-toolbar
                            class=""
                            aria-label="Toolbar with button groups and dropdown menu"
                        >
                            <b-button-group class="mx-1 btn-group">
                                <b-button
                                    disabled
                                    style="margin-right: 0.25rem !important"
                                >
                                    {{ totalProcessMails }}
                                    <b-icon
                                        v-if="!loadInProcessMails"
                                        icon="arrow-clockwise"
                                        animation="spin-pulse"
                                    ></b-icon>
                                </b-button>
                            </b-button-group>
                        </b-button-toolbar>
                    </template>
                </KTCodePreview>
            </div>
        </div>
    </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import ProcessItem from "@/view/pages/dashboard/list-items-comp/ProcessItem.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapActions("mails", ["getProcessMails"]),
        ...mapGetters("mails", ["processMails"]),
        ...mapGetters("mails", ["totalProcessMails"]),
        ...mapGetters("mails", ["filterU"]),
        ...mapGetters("mails", ["loadInProcessMails"]),
    },
    methods: {
        formatDate(date_created) {
            const date = new Date(date_created);
            // console.log(date);
            return `${("0" + date.getDate()).slice(-2)}-${(
                "0" +
                (date.getMonth() + 1)
            ).slice(-2)} ${date.getFullYear()}`;
        },
    },
    created() {
        // this.getProcessMails;
    },
    components: {
        KTCodePreview,
        ProcessItem,
    },
    mounted() {},
};
</script>
