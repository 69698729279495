<template>
    <span>
        <router-link
            v-if="hasAccessToConv(item)"
            :class="settings.classes"
            v-b-tooltip.hover.top
            :title="settings.tooltip ? item.customer.email : ''"
            :to="{ name: 'conversation', params: { id: item.id } }"
        >
            <slot></slot>
        </router-link>
        <a v-else href="#" @click="dialog = true" :class="settings.classes"
            ><slot></slot
        ></a>

        <v-dialog v-model="dialog" max-width="440px">
            <v-card class="spam-dialog">
                <v-card-title class="text-h6" style="word-break: break-word">
                    Nu aveti permisiune sa vizualizati aceasta conversatie!
                </v-card-title>
                <v-card-text> </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="blue darken-1" @click="dialog = false" text>Cancel</v-btn> -->
                    <v-btn color="blue darken-1" @click="dialog = false" text>OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            dialog: false,
        };
    },
    props: ["item", "linktext", "settings"],
    computed: {
        ...mapGetters("departments", ["departments"]),
        ...mapGetters(["user"]),
    },
    methods: {
        hasAccessToConv(item) {
            let hasAccess = true;

            if (item.is_following) {
                return hasAccess;
            }
            if (item.status == "spam") {
                return hasAccess;
            }

            let depart = this.departments.find((dep) => dep.id === item.mailboxId);
            // nu e departamentul lui face redirect
            if (!depart) {
                // console.log("fa redirect 1");
                hasAccess = false;
            }

            // nu e conversatia lui si nu e manager pe departament face redicer
            else if (
                item.assignee?.id !== this.user.id &&
                !depart.isManager &&
                item.status !== "active"
            ) {
                hasAccess = false;
            }
            return hasAccess;
        },
    },
};
</script>
