<template>
    <b-button-group class="mx-1 btn-group">
        <b-button
            :variant="!disabled ? `outline-${variant}` : ''"
            class="ml-1 spam-btn btn-secondary"
            @click="mailSpam"
            :disabled="disabled"
        >
            <span class="svg-icon" :class="`svg-icon-${variant}`">
                <inline-svg src="/media/svg/icons/Code/Warning-1-circle.svg"></inline-svg>
            </span>
            SPAM</b-button
        >
        <SpamAllDialog
            v-if="confirmSpam"
            :spamDialog="confirmSpam"
            :inConversation="inConversation"
            :conversation="conversation"
            @input="undoItem"
            @reload-conversation="reloadConversation"
            @spammails="spamMails"
        />
        <v-dialog v-model="spamMailsDialog" max-width="400">
            <v-card class="">
                <v-card-title class="text-h6 grey lighten-2">
                    Status adaugare conversatii selectate in spam
                </v-card-title>

                <v-card-text style="word-break: break-word" class="text-subtitle-1">
                    {{ message }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="spamMailsDialog = false">
                        Inchide
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </b-button-group>
</template>

<script>
import { mapActions } from "vuex";
import SpamAllDialog from "@/view/content/buttons/SpamAllDialog.vue";
export default {
    data() {
        return {
            confirmSpam: false,
            items: [],
            spamMailsDialog: false,
            message: "",
        };
    },
    props: [
        "multiple",
        "conversation",
        "inConversation",
        "variant",
        "disabled",
        "selectedMails",
    ],
    computed: {
        departments() {
            return this.$store.getters["departments/allDepartments"];
        },
    },
    watch: {
        selectedMails() {
            // console.log(this.selectedMails);
        },
    },
    components: {
        SpamAllDialog,
    },
    methods: {
        ...mapActions("mails", ["selectMail"]),
        ...mapActions("mails", ["resetSelected"]),
        mailSpam() {
            if (this.inConversation) {
                this.resetSelected(false);
                this.selectMail(this.conversation.id);
            }

            this.confirmSpam = !this.confirmSpam;
            this.$emit("reload-conversation", true);
        },
        undoItem(item) {
            this.confirmSpam = item;
        },
        reloadConversation() {
            this.confirmSpam = false;
            this.$emit("reload-conversation", true);
        },
        spamMails(all_mailboxes) {
            let payload = {
                departments: this.departments,
                addRuleForAll: all_mailboxes,
            };
            this.$store.dispatch("mails/spamMail", payload).then((response) => {
                if (response.success == false && response.existing_workflows) {
                    this.message =
                        "Nici o conversatie nu s-a adaugat in spam, din cauza ca exista reguli pe aceste conversatii. Verificati si prelucrati fiecare conversatie in parte!";
                }

                if (response.success && response.existing_workflows) {
                    this.message =
                        "Cel putin pe o conversatie nu s-a adaugat regula. Verificati si prelucrati pe o singura conversatie in parte!";
                }

                if (response.success && response.existing_workflows == false) {
                    this.message =
                        "Toate conversatiile au fost adaugate in spam cu succes!";
                }
                this.confirmSpam = false;
                this.spamMailsDialog = true;
            });
            // this.spamMailsDialog = payload.spamMailsDialog;
        },
    },
};
</script>
