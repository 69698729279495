<template>
    <div
        id="kt_header"
        class="header header-fixed dashboard-employee-header w-100"
        style="
            position: unset !important;
            top: unset !important;
            height: unset !important;
        "
    >
        <div
            class="d-flex align-items-center justify-content-between flex-wrap container-fluid container-navbar-depart"
        >
            <div v-if="departments" class="col-12 col-lg-auto">
                <div class="d-flex justify-content-between align-items-center">
                    <b-button
                        @click="departIsShow = !departIsShow"
                        class="d-block d-md-none d-custom"
                        ><b-icon-chevron-down></b-icon-chevron-down
                    ></b-button>
                </div>
            </div>
            <div v-show="departIsShow" class="col col-md overflow-auto horizontal-scroll">
                <div
                    id="kt_header_menu"
                    class="header-menu header-menu-mobile header-menu-layout-default"
                >
                    <ul v-if="departments && department" class="menu-nav">
                        <li
                            v-if="curentDepartament.isManager || user.role === 'admin'"
                            data-menu-toggle="hover"
                            class="svg-icon svg-icon-2x svg-icon-primary menu-item"
                            @click="changeShowUsers(showUsers ? false : true)"
                            :title="
                                (showUsers ? 'Ascunde' : 'Afiseaza') + ' utilizatorii'
                            "
                            v-b-tooltip.hover.right
                        >
                            <inline-svg
                                :src="
                                    showUsers
                                        ? '/media/svg/icons/Navigation/Angle-double-up.svg'
                                        : '/media/svg/icons/Navigation/Angle-double-down.svg'
                                "
                            />
                        </li>
                        <router-link
                            :to="{
                                name: 'user',
                                params: { user: user.id },
                            }"
                            custom
                            v-slot="{ href, navigate }"
                        >
                            <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[
                                    !department.hasOwnProperty('id') &&
                                        'menu-item-active',
                                ]"
                                @click="getDepart(0), getAllUsers(false), resetSearch()"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-text"> Toate </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            :to="{
                                name: 'user',
                                params: { department: depart.id, user: user.id },
                            }"
                            custom
                            v-slot="{ href, navigate }"
                            v-for="(depart, key, i) in departments"
                            :key="i"
                            :depart="depart"
                            replace
                        >
                            <li
                                @click="
                                    getDepart(depart.id), getAllUsers(true), resetSearch()
                                "
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[
                                    department.id === depart.id && 'menu-item-active',
                                ]"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-text"> {{ depart.name }} </span>
                                    <span
                                        class="svg-icon svg-icon-2x svg-icon-primary"
                                        v-if="depart.isManager"
                                    >
                                        <inline-svg
                                            src="/media/svg/icons/Navigation/Angle-down.svg"
                                        />
                                    </span>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-auto">
                <router-link to="/spam-mails" v-slot="{ href, navigate }" custom>
                    <a :href="href" @click="navigate">
                        <b-button variant="outline-danger">
                            <span class="svg-icon svg-icon-dnger">
                                <inline-svg
                                    src="/media/svg/icons/Code/Warning-1-circle.svg"
                                ></inline-svg>
                            </span>

                            Spam</b-button
                        >
                    </a>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "KTMenu",
    data() {
        return {
            departIsShow: window.innerWidth > 991,
        };
    },
    methods: {
        changeShowUsers(status) {
            this.$store.commit("departments/showUsers", status);
        },
        hasActiveChildren(match) {
            return this.$route["path"].indexOf(match) !== -1;
        },
        getDepart(depart_id) {
            let payload = {
                assignedTo: this.user.id,
            };

            this.$store.dispatch("mails/getUserName", this.user.id);
            this.$store.dispatch("departments/getDepartment", depart_id);

            if (depart_id) {
                payload.mailboxId = depart_id;
            } else {
                let departs_ids = "";
                for (const depart in this.departments) {
                    departs_ids =
                        departs_ids +
                        (depart > 0 ? "," : "") +
                        this.departments[depart].id;
                }
                payload.mailboxId = departs_ids;
            }
            this.$store.dispatch("mails/getConversations", payload);
        },
        getAllUsers(value) {
            this.$store.dispatch("users/getAllUsers", value);
        },
        resetSearch() {
            this.$store.dispatch("mails/filterMails", false).then(() => {
                this.$store.dispatch("mails/inputSearch", "");
            });
        },
    },
    computed: {
        ...mapActions("departments", ["getDepartments"]),
        ...mapGetters("departments", ["departments"]),
        ...mapGetters("departments", ["department"]),
        ...mapGetters(["user"]),
        curentDepartament() {
            return this.$store.getters["departments/department"];
        },
        showUsers() {
            return this.$store.getters["departments/showUsers"];
        },
    },
    created() {
        // console.log("created from Departments");
        // this.$store.dispatch("departments/getDepartments").then(() => {
        //   if (this.$router.history.current.params.department) {
        //     this.$store.dispatch(
        //       "departments/getDepartment",
        //       +this.$router.history.current.params.department
        //     );
        //   }
        // });
    },
    destroyed() {
        this.$store.dispatch("departments/resetDepartment");
    },
};
</script>

<style scoped>
/* mmenu scrollbar */
.horizontal-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.horizontal-scroll::-webkit-scrollbar-track {
    background: #d8d8d8;
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Handle */
.horizontal-scroll::-webkit-scrollbar-thumb {
    background: grey;
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.horizontal-scroll::-webkit-scrollbar-thumb:window-inactive {
    background: grey;
}

.container-navbar-depart {
    padding: 0 15px;
}

@media (max-width: 992px) {
    .container-navbar-depart {
        padding: 0;
    }
}

@media (min-width: 960px) and (max-width: 991px) {
    .d-custom {
        display: block !important;
    }
}
</style>
