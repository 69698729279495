var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header header-fixed dashboard-employee-header w-100",staticStyle:{"position":"unset !important","top":"unset !important","height":"unset !important"},attrs:{"id":"kt_header"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('b-button',{staticClass:"d-block d-md-none d-custom",on:{"click":function($event){_vm.usersIsShow = !_vm.usersIsShow}}},[_c('b-icon-chevron-down')],1)],1),(_vm.usersIsShow)?_c('div',{staticClass:"container-fluid d-flex align-items-center justify-content-between w-100 overflow-auto horizontal-scroll"},[_c('div',{staticClass:"header-menu-wrapper-left w-100"},[_c('div',{staticClass:"header-menu header-menu-mobile header-menu-layout-default",attrs:{"id":"kt_header_menu"}},[(_vm.agents.length)?_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":_vm.allUserUrl,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"menu-item",class:[_vm.filterU === 'all' && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":function($event){_vm.getUser('all'), _vm.resetSearch()}}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Toti ")])])])]}}],null,false,2997622864)}),_vm._l((_vm.agents),function(user,key,i){return _c('router-link',{key:i,attrs:{"custom":"","to":{
                            name: 'user',
                            params: { department: _vm.department.id, user: user.id },
                        },"replace":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],staticClass:"menu-item",class:[_vm.filterU === user.id && 'menu-item-active'],attrs:{"data-menu-toggle":"hover","title":user.firstName + ' ' + user.lastName},on:{"click":function($event){_vm.getUser(user.id), _vm.resetSearch()}}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_c('span',{staticClass:"symbol symbol-35 mr-1",class:[
                                            user.status === 'active'
                                                ? 'symbol-light-success active'
                                                : '',
                                            user.status === 'vacation'
                                                ? 'symbol-light-danger vacation'
                                                : '',
                                            user.status === 'busy'
                                                ? 'symbol-light-warning busy'
                                                : '',
                                        ]},[(
                                                user.photoUrl !=
                                                'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                                            )?_c('span',{staticClass:"symbol-label font-size-h5 font-weight-bold symbol-photo"},[_c('img',{attrs:{"alt":"Pic","src":user.photoUrl}})]):_c('span',{staticClass:"symbol-label font-size-h5 font-weight-bold"},[_vm._v(" "+_vm._s(user.firstName.charAt(0) + user.lastName.charAt(0))+" ")])]),_vm._v(" "+_vm._s(user.nickName ? user.nickName : user.firstName)+" "),_c('span',{staticClass:"label label-rounded label-light-success font-weight-bolder ml-1",class:[
                                            user.status === 'active'
                                                ? 'label-light-success'
                                                : '',
                                            user.status === 'vacation'
                                                ? 'label-light-danger'
                                                : '',
                                            user.status === 'busy'
                                                ? 'label-light-warning'
                                                : '',
                                        ]},[_vm._v(_vm._s(user.conversationsCount))])])])])]}}],null,true)})})],2):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }