<template>
    <!--begin::Item-->
    <div class="d-flex align-items-start list-item card-spacer-x py-4">
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
            <!--begin::Actions-->
            <div class="d-flex align-items-center mr-1">
                <span style="width: 60px"
                    ><span class="font-weight-bolder conv-wrp"
                        >#{{ mail.number
                        }}<span
                            class="label label-sm label-success conv-count"
                            v-if="mail.threadsCount > 1"
                            >{{ mail.threadsCount }}
                        </span></span
                    >
                    <br />
                    <span class="mr-1"
                        ><Tag :tag="mail._embedded.tags" :id="mail.id" :key="mail.id"
                    /></span>
                    <i
                        class="fas fa-check text-warning"
                        v-if="mail.state === 'confirmed'"
                        :id="'confirmed_' + mail.id"
                    ></i>
                </span>
            </div>
            <!--end::Actions-->
            <!--begin::Author-->
            <div class="d-flex align-items-center flex-wrap w-xxl-200px mr-3 w-180px">
                <conversation-list-link
                    :item="mail"
                    :settings="{
                        classes: 'font-weight-bolder text-dark-75 text-hover-primary',
                        tooltip: true,
                    }"
                >
                    <span class="d-flex flex-row">
                        {{ mail.customer.firstName || "" }}
                        {{ mail.customer.lastName || "" }}
                    </span>
                    <span class="" :class="mail.customer.firstName ? 'text-muted' : ''"
                        >{{ mail.customer.email.slice(0, 25)
                        }}{{ mail.customer.email.length > 25 ? "..." : "" }}</span
                    >
                </conversation-list-link>
            </div>
            <!--end::Author-->
        </div>
        <!--end::Toolbar-->
        <!--begin::Info-->
        <div class="flex-grow-1 mt-1 mr-2">
            <div class="text-dark mb-1">
                <span v-if="mail.scheduled"> <b-icon icon="clock"></b-icon>&nbsp;</span>
                <!--begin::Heading-->
                <v-icon v-if="mail.hasAttachments" color="grey" dark class="mr-1">
                    mdi-attachment
                </v-icon>
                <conversation-list-link
                    :item="mail"
                    :settings="{
                        classes: 'text-dark',
                    }"
                >
                    <span class="font-size-lg mr-2"
                        >{{ mail.subject.slice(0, 120)
                        }}{{ mail.subject.length > 120 ? "..." : "" }}</span
                    >
                </conversation-list-link>
                <!--end::Heading-->
            </div>

            <!--begin::Badges-->
            <div class="mt-2">
                <span
                    class="label label-light-primary font-weight-bold label-inline mr-1"
                    :class="{
                        'label-light-primary': mail.pendingStatus,
                        'label-light-danger': mail.isLastSendStatusError,
                        'label-light-success':
                            !mail.pendingStatus && !mail.isLastSendStatusError,
                    }"
                >
                    {{ itemStatus }}</span
                >
            </div>
            <!--end::Badges-->
        </div>
        <!--end::Info-->
        <!--begin::Details-->
        <div class="pl-2">
            <div
                class="d-flex align-items-center justify-content-end flex-wrap pb-2 w-80px"
            >
                <!--begin::User Photo-->
                <div
                    class="symbol symbol-35 symbol-light mr-1 flex-shrink-0"
                    v-b-tooltip.hover.top
                    :title="mail.departament"
                >
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                            <inline-svg :src="getSVG(mail.departament)" />
                        </span>
                    </div>
                </div>
                <!--begin::User Photo-->
                <div class="symbol symbol-35" v-if="mail.assignee">
                    <div
                        class="symbol-label"
                        :style="{
                            backgroundImage: `url(${mail.assignee.photoUrl})`,
                        }"
                        v-b-tooltip.hover.top
                        :title="mail.assignee.firstName + ' ' + mail.assignee.lastName"
                    ></div>
                </div>
                <!--end::User Photo-->
            </div>
            <div class="d-flex align-items-center justify-content-end flex-wrap">
                <!--begin::Datetime-->
                <div class="font-weight-bolder">
                    {{ formatDate(mail.createdDate) }}
                </div>
                <!--end::Datetime-->
            </div>
        </div>
        <!--end::Details-->
    </div>
    <!--end::Item-->
</template>

<script>
import { mapGetters } from "vuex";
import Tag from "@/view/pages/dashboard/Tag.vue";
import ConversationListLink from "@/view/pages/conversation/ConversationListLink";

export default {
    props: ["mail"],
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["layoutConfig"]),
        itemStatus() {
            if (this.mail.isLastSendStatusError) {
                return "Netrimis";
            }

            if (this.mail.pendingStatus) {
                return this.mail.pendingStatus;
            }

            if (this.mail.statusName == "In asteptare") {
                return "Trimis";
            }
            return this.mail.statusName;
        },
    },
    methods: {
        formatDate(date_created) {
            const date = new Date(date_created);
            return `${("0" + date.getDate()).slice(-2)}-${(
                "0" +
                (date.getMonth() + 1)
            ).slice(-2)} ${date.getFullYear()}`;
        },
        getSVG(departament) {
            return this.layoutConfig("departaments." + departament);
        },
    },
    created() {},
    components: { Tag, ConversationListLink },
    mounted() {},
};
</script>
