var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-start list-item card-spacer-x py-4",class:{ 'red lighten-4': _vm.isPending }},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex align-items-center mr-1"},[_c('span',{staticStyle:{"width":"60px"}},[_c('span',{staticClass:"font-weight-bolder conv-wrp"},[_vm._v("#"+_vm._s(_vm.mail.number)+" "),(_vm.mail.threadsCount > 1)?_c('span',{staticClass:"label label-sm label-success conv-count"},[_vm._v(_vm._s(_vm.mail.threadsCount)+" ")]):_vm._e()]),_c('br'),(_vm.mail.state === 'confirmed')?_c('i',{staticClass:"fas fa-check text-warning",attrs:{"id":'confirmed_' + _vm.mail.id}}):_vm._e(),_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('span',{staticClass:"mr-1"},[_c('Tag',{attrs:{"tag":_vm.mail._embedded.tags,"id":_vm.mail.id}})],1)])])]),_c('div',{staticClass:"d-flex align-items-center flex-wrap w-xxl-200px mr-3 w-180px"},[_c('conversation-list-link',{attrs:{"item":_vm.mail,"settings":{
                    classes: 'font-weight-bolder text-dark-75 text-hover-primary',
                    tooltip: true,
                }}},[_c('span',{staticClass:"d-flex flex-row"},[_vm._v(" "+_vm._s(_vm.mail.customer.firstName || "")+" "+_vm._s(_vm.mail.customer.lastName || "")+" ")]),_c('span',{class:_vm.mail.customer.firstName ? 'text-muted' : ''},[_vm._v(_vm._s(_vm.mail.customer.email.slice(0, 25))+_vm._s(_vm.mail.customer.email.length > 25 ? "..." : ""))])])],1)]),_c('div',{staticClass:"flex-grow-1 mt-1 mr-2"},[_c('div',{staticClass:"text-dark mb-1"},[(_vm.mail.hasAttachments)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"grey","dark":""}},[_vm._v(" mdi-attachment ")]):_vm._e(),(_vm.mail.scheduled)?_c('span',[_c('b-icon',{attrs:{"icon":"clock"}}),_vm._v(" ")],1):_vm._e(),_c('conversation-list-link',{attrs:{"item":_vm.mail,"settings":{
                    classes: 'text-dark',
                }}},[_c('span',{staticClass:"font-size-lg mr-2"},[_vm._v(_vm._s(_vm.mail.subject.slice(0, 120))+_vm._s(_vm.mail.subject.length > 120 ? "..." : ""))])])],1),_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"label label-light-primary font-weight-bold label-inline mr-1",class:{
                    'label-light-primary': _vm.mail.pendingStatus,
                    'label-light-danger': _vm.mail.isLastSendStatusError,
                    'label-light-success':
                        !_vm.mail.pendingStatus && !_vm.mail.isLastSendStatusError,
                }},[_vm._v(" "+_vm._s(_vm.itemStatus))])])]),_c('div',{staticClass:"pl-2"},[_c('div',{staticClass:"d-flex align-items-center justify-content-end flex-wrap pb-2 w-80px"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"symbol symbol-35 symbol-light mr-1 flex-shrink-0",attrs:{"title":_vm.mail.departament}},[_c('div',{staticClass:"symbol-label"},[_c('span',{staticClass:"svg-icon svg-icon-2x svg-icon-primary"},[_c('inline-svg',{attrs:{"src":_vm.getSVG(_vm.mail.departament)}})],1)])]),(_vm.mail.assignee)?_c('div',{staticClass:"symbol symbol-35"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"symbol-label",style:({
                        backgroundImage: `url(${_vm.mail.assignee.photoUrl})`,
                    }),attrs:{"title":_vm.mail.assignee.firstName + ' ' + _vm.mail.assignee.lastName}})]):_vm._e()]),_c('div',{staticClass:"d-flex align-items-center justify-content-end flex-wrap"},[_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.mail.createdDate))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }