<template>
    <!--begin::Item-->
    <div class="d-flex align-items-start list-item card-spacer-x py-4">
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
            <!--begin::Actions-->
            <div class="d-flex flex-column mr-1 conv-wrp">
                <label
                    class="checkbox checkbox-inline checkbox-warning flex-shrink-0 mr-3"
                >
                    <input
                        type="checkbox"
                        @click="setSelectedMails(mail.id)"
                        :value="mail.id"
                        v-model="selected"
                    />
                    <span></span>
                </label>
                <span
                    class="label label-sm label-success conv-count"
                    v-if="mail.threadsCount > 1"
                    >{{ mail.threadsCount }}
                </span>
                <span class="mr-1"><Tag :tag="mail._embedded.tags" :id="mail.id" /></span>
            </div>
            <!--end::Actions-->
            <!--begin::Author-->
            <div class="d-flex align-items-center flex-wrap w-xxl-200px mr-3 w-180px">
                <conversation-list-link
                    :item="mail"
                    :settings="{
                        classes: 'font-weight-bolder text-dark-75 text-hover-primary',
                        tooltip: true,
                    }"
                >
                    <span class="d-flex flex-row">
                        {{ mail.customer.firstName || "" }}
                        {{ mail.customer.lastName || "" }}
                    </span>
                    <span class="" :class="mail.customer.firstName ? 'text-muted' : ''"
                        >{{ mail.customer.email.slice(0, 25)
                        }}{{ mail.customer.email.length > 25 ? "..." : "" }}</span
                    >
                </conversation-list-link>
            </div>
            <!--end::Author-->
        </div>
        <!--end::Toolbar-->
        <!--begin::Info-->
        <div class="flex-grow-1 mt-1 mr-2">
            <div class="text-dark mb-1">
                <v-icon v-if="mail.hasAttachments" color="grey" dark class="mr-1">
                    mdi-attachment
                </v-icon>
                <span v-if="mail.scheduled"> <b-icon icon="clock"></b-icon>&nbsp;</span>
                <!--begin::Heading-->
                <conversation-list-link
                    :item="mail"
                    :settings="{
                        classes: 'text-dark',
                    }"
                >
                    <span class="font-size-lg mr-2"
                        >{{ mail.subject.slice(0, 120)
                        }}{{ mail.subject.length > 120 ? "..." : "" }}</span
                    >
                </conversation-list-link>
                <!--end::Heading-->
            </div>
            <!--begin::Badges-->
            <div class="mt-2" v-if="mail.pendingStatus">
                <span
                    class="label label-light-primary font-weight-bold label-inline mr-1"
                    :class="
                        mail.pendingStatus ? 'label-light-primary' : 'label-light-info'
                    "
                    >{{ mail.pendingStatus }}</span
                >
            </div>
            <div class="mt-2" v-if="mail.isLastSendStatusError">
                <span
                    class="label label-light-danger font-weight-bold label-inline mr-1"
                    :class="'label-light-danger'"
                    >Netrimis</span
                >
            </div>
            <!--end::Badges-->

            <!--end::Badges-->
        </div>
        <!--end::Info-->
        <!--begin::Details-->
        <div class="pl-2">
            <div
                class="d-flex align-items-center justify-content-end flex-wrap pb-2 w-100px"
            >
                <!--begin::User Photo-->
                <div
                    class="symbol symbol-35 symbol-light mr-1 flex-shrink-0"
                    v-b-tooltip.hover.top
                    :title="mail.departament"
                    v-if="mail.departament != 'General'"
                >
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-2x svg-icon-primary">
                            <inline-svg :src="getSVG(mail.departament)" />
                        </span>
                    </div>
                </div>
                <!--begin::User Photo-->
                <div class="symbol symbol-35" v-if="mail.assignee">
                    <div
                        v-if="mail.assignee.status !== 'vacation'"
                        class="symbol-label"
                        :style="{
                            backgroundImage: `url(${mail.assignee.photoUrl})`,
                        }"
                        v-b-tooltip.hover.top
                        :title="mail.assignee.firstName + ' ' + mail.assignee.lastName"
                    ></div>
                    <div
                        v-else-if="
                            mail._embedded.suggestion != null &&
                            mail._embedded.suggestion.firstName != 'Admin'
                        "
                        class="symbol-label"
                        :style="{
                            backgroundImage: `url(${mail._embedded.suggestion.photoUrl})`,
                            border: '1px solid red',
                            opacity: 0.3,
                        }"
                        v-b-tooltip.hover.top
                        :title="
                            'Sugestie: ' +
                            mail._embedded.suggestion.firstName +
                            ' ' +
                            mail._embedded.suggestion.lastName
                        "
                    ></div>
                </div>
                <div
                    v-else-if="
                        mail._embedded.suggestion != null &&
                        mail._embedded.suggestion.firstName != 'Admin'
                    "
                    class="symbol symbol-35"
                >
                    <div
                        class="symbol-label"
                        :style="{
                            backgroundImage: `url(${mail._embedded.suggestion.photoUrl})`,
                            opacity: 0.3,
                        }"
                        v-b-tooltip.hover.top
                        :title="
                            'Sugestie: ' +
                            mail._embedded.suggestion.firstName +
                            ' ' +
                            mail._embedded.suggestion.lastName
                        "
                    ></div>
                </div>
                <!--end::User Photo-->
            </div>
            <div class="d-flex align-items-center justify-content-end flex-wrap">
                <!--begin::Datetime-->
                <div class="font-weight-bolder">
                    {{ mail.updatedAt | moment("DD-MM-YY HH:mm") }}
                </div>
                <!--end::Datetime-->
            </div>
        </div>
        <!--end::Details-->
    </div>
    <!--end::Item-->
</template>

<script>
import { mapGetters } from "vuex";
import Tag from "@/view/pages/dashboard/Tag.vue";
import ConversationListLink from "@/view/pages/conversation/ConversationListLink";

export default {
    props: ["mail"],
    data() {
        return {
            selected: [],
        };
    },
    computed: {
        ...mapGetters(["layoutConfig"]),
        ...mapGetters("mails", ["selectedMails"]),
    },
    watch: {
        selectedMails() {
            if (this.selectedMails.indexOf(this.mail.id) === -1) {
                this.selected = [];
            }
        },
    },

    methods: {
        setSelectedMails(selected) {
            if (this.selected.length == 0) {
                this.$store.commit("mails/selectMail", selected);
            } else {
                this.$store.commit("mails/unselectMail", selected);
            }
        },
        getSVG(departament) {
            return this.layoutConfig("departaments." + departament);
        },
    },
    created() {},
    components: { Tag, ConversationListLink },
    mounted() {
        if (this.selectedMails.indexOf(this.mail.id) > -1) {
            this.selected = true;
        }
    },
};
</script>
<style scoped>
.conv-count {
    right: -5px;
}
</style>
