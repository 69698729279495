<template>
    <div
        id="kt_header"
        class="header header-fixed dashboard-employee-header w-100"
        style="
            position: unset !important;
            top: unset !important;
            height: unset !important;
        "
    >
        <div class="d-flex justify-content-between align-items-center">
            <b-button
                @click="usersIsShow = !usersIsShow"
                class="d-block d-md-none d-custom"
                ><b-icon-chevron-down></b-icon-chevron-down
            ></b-button>
        </div>
        <div
            v-if="usersIsShow"
            class="container-fluid d-flex align-items-center justify-content-between w-100 overflow-auto horizontal-scroll"
        >
            <div class="header-menu-wrapper-left w-100">
                <div
                    id="kt_header_menu"
                    class="header-menu header-menu-mobile header-menu-layout-default"
                >
                    <ul v-if="agents.length" class="menu-nav">
                        <router-link :to="allUserUrl" v-slot="{ href, navigate }" custom>
                            <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[filterU === 'all' && 'menu-item-active']"
                                @click="getUser('all'), resetSearch()"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-text"> Toti </span>
                                </a>
                            </li>
                        </router-link>

                        <router-link
                            v-slot="{ href, navigate }"
                            custom
                            v-for="(user, key, i) in agents"
                            :key="i"
                            :to="{
                                name: 'user',
                                params: { department: department.id, user: user.id },
                            }"
                            replace
                        >
                            <li
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[filterU === user.id && 'menu-item-active']"
                                @click="getUser(user.id), resetSearch()"
                                v-b-tooltip.hover.right
                                :title="user.firstName + ' ' + user.lastName"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-text">
                                        <span
                                            class="symbol symbol-35 mr-1"
                                            :class="[
                                                user.status === 'active'
                                                    ? 'symbol-light-success active'
                                                    : '',
                                                user.status === 'vacation'
                                                    ? 'symbol-light-danger vacation'
                                                    : '',
                                                user.status === 'busy'
                                                    ? 'symbol-light-warning busy'
                                                    : '',
                                            ]"
                                        >
                                            <span
                                                v-if="
                                                    user.photoUrl !=
                                                    'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                                                "
                                                class="symbol-label font-size-h5 font-weight-bold symbol-photo"
                                            >
                                                <img alt="Pic" :src="user.photoUrl" />
                                            </span>
                                            <span
                                                v-else
                                                class="symbol-label font-size-h5 font-weight-bold"
                                            >
                                                {{
                                                    user.firstName.charAt(0) +
                                                    user.lastName.charAt(0)
                                                }}
                                            </span>
                                        </span>
                                        {{
                                            user.nickName ? user.nickName : user.firstName
                                        }}
                                        <span
                                            class="label label-rounded label-light-success font-weight-bolder ml-1"
                                            :class="[
                                                user.status === 'active'
                                                    ? 'label-light-success'
                                                    : '',
                                                user.status === 'vacation'
                                                    ? 'label-light-danger'
                                                    : '',
                                                user.status === 'busy'
                                                    ? 'label-light-warning'
                                                    : '',
                                            ]"
                                            >{{ user.conversationsCount }}</span
                                        >
                                    </span>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "KTMenu",
    data() {
        return {
            usersIsShow: window.innerWidth > 991,
        };
    },
    methods: {
        hasActiveChildren(match) {
            return this.$route["path"].indexOf(match) !== -1;
        },
        getUser(name) {
            this.$store.dispatch("mails/getUserName", name);
            this.$store.dispatch("mails/getConversations", {
                assignedTo: name !== "all" ? name : 0,
                mailboxId: this.department.id ? this.department.id : this.departments,
            });
        },
        resetSearch() {
            this.$store.dispatch("mails/filterMails", false).then(() => {
                this.$store.dispatch("mails/inputSearch", "");
            });
        },
        showUsers(status) {
            this.$store.commit("departments/showUsers", status);
        },
    },
    computed: {
        ...mapActions("users", ["getUsers"]),
        ...mapGetters("users", ["users"]),
        ...mapGetters("mails", ["filterU"]),
        ...mapGetters("users", ["userStatus"]),
        ...mapGetters("users", ["userId"]),
        agents() {
            return this.$store.getters["departments/agents"];
        },
        department() {
            return this.$store.getters["departments/department"];
        },
        departments() {
            const departments = this.$store.getters["departments/departments"];
            let departments_ids = [];
            departments.map((department) => {
                departments_ids.push(department.id);
            });
            return departments_ids;
        },
        allUserUrl() {
            if (this.department.id) {
                return { name: "department", params: { department: this.department.id } };
            }
            return { name: "dashboard" };
        },
    },
    created() {
        this.getUsers;
        if (this.$router.history.current.params.user) {
            this.$store.dispatch(
                "mails/getUserName",
                +this.$router.history.current.params.user
            );
        }
    },
};
</script>

<style scoped>
/* mmenu scrollbar */
.horizontal-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.horizontal-scroll::-webkit-scrollbar-track {
    background: #d8d8d8;
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Handle */
.horizontal-scroll::-webkit-scrollbar-thumb {
    background: grey;
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.horizontal-scroll::-webkit-scrollbar-thumb:window-inactive {
    background: grey;
}
.symbol-label img {
    width: 90%;
    border-radius: 0.42rem;
}

div .symbol.active .symbol-label.symbol-photo {
    background-color: #1bc5bd;
}
div .symbol.busy .symbol-label.symbol-photo {
    background-color: #f3c200;
}
div .symbol.vacation .symbol-label.symbol-photo {
    background-color: #f64e60;
}

@media (min-width: 960px) and (max-width: 991px) {
    .d-custom {
        display: block !important;
    }
}
</style>
