var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header header-fixed dashboard-employee-header w-100",staticStyle:{"position":"unset !important","top":"unset !important","height":"unset !important"},attrs:{"id":"kt_header"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap container-fluid container-navbar-depart"},[(_vm.departments)?_c('div',{staticClass:"col-12 col-lg-auto"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('b-button',{staticClass:"d-block d-md-none d-custom",on:{"click":function($event){_vm.departIsShow = !_vm.departIsShow}}},[_c('b-icon-chevron-down')],1)],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.departIsShow),expression:"departIsShow"}],staticClass:"col col-md overflow-auto horizontal-scroll"},[_c('div',{staticClass:"header-menu header-menu-mobile header-menu-layout-default",attrs:{"id":"kt_header_menu"}},[(_vm.departments && _vm.department)?_c('ul',{staticClass:"menu-nav"},[(_vm.curentDepartament.isManager || _vm.user.role === 'admin')?_c('li',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],staticClass:"svg-icon svg-icon-2x svg-icon-primary menu-item",attrs:{"data-menu-toggle":"hover","title":(_vm.showUsers ? 'Ascunde' : 'Afiseaza') + ' utilizatorii'},on:{"click":function($event){return _vm.changeShowUsers(_vm.showUsers ? false : true)}}},[_c('inline-svg',{attrs:{"src":_vm.showUsers
                                    ? '/media/svg/icons/Navigation/Angle-double-up.svg'
                                    : '/media/svg/icons/Navigation/Angle-double-down.svg'}})],1):_vm._e(),_c('router-link',{attrs:{"to":{
                            name: 'user',
                            params: { user: _vm.user.id },
                        },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"menu-item",class:[
                                !_vm.department.hasOwnProperty('id') &&
                                    'menu-item-active',
                            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":function($event){_vm.getDepart(0), _vm.getAllUsers(false), _vm.resetSearch()}}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Toate ")])])])]}}],null,false,4088313677)}),_vm._l((_vm.departments),function(depart,key,i){return _c('router-link',{key:i,attrs:{"to":{
                            name: 'user',
                            params: { department: depart.id, user: _vm.user.id },
                        },"custom":"","depart":depart,"replace":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"menu-item",class:[
                                _vm.department.id === depart.id && 'menu-item-active',
                            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":function($event){_vm.getDepart(depart.id), _vm.getAllUsers(true), _vm.resetSearch()}}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(depart.name)+" ")]),(depart.isManager)?_c('span',{staticClass:"svg-icon svg-icon-2x svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Navigation/Angle-down.svg"}})],1):_vm._e()])])]}}],null,true)})})],2):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-auto"},[_c('router-link',{attrs:{"to":"/spam-mails","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('b-button',{attrs:{"variant":"outline-danger"}},[_c('span',{staticClass:"svg-icon svg-icon-dnger"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Code/Warning-1-circle.svg"}})],1),_vm._v(" Spam")])],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }