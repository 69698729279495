<template>
    <div>
        <div class="row">
            <div class="col-md-12 close-mails">
                <KTCodePreview
                    class="finalised-mails"
                    v-bind:title="'Mail-uri finalizate'"
                >
                    <template v-slot:preview>
                        <v-simple-table fixed-header v-if="finalizedMails.length > 0">
                            <template v-slot:default>
                                <div class="card card-custom card-stretch">
                                    <div class="card-body table-responsive px-0">
                                        <!--begin::Items-->
                                        <div
                                            class="list mail-table list-hover min-w-500px"
                                        >
                                            <CompletedItem
                                                v-for="(mail, i) in finalizedMails"
                                                :key="i"
                                                :mail="mail"
                                            >
                                            </CompletedItem>
                                            <p
                                                v-if="
                                                    totalFinalizedMails >
                                                    finalizedMails.length
                                                "
                                                class="no-mails text-primary"
                                            >
                                                ({{ finalizedMails.length }} din
                                                {{ totalFinalizedMails }}) incarcate<br />
                                                <span class="text-danger"
                                                    >Restul mailurilor le poți găsi în
                                                    pagina de
                                                    <router-link
                                                        to="/advanced-search"
                                                        v-slot="{ href, navigate }"
                                                    >
                                                        <a :href="href" @click="navigate"
                                                            >Cautare Avansata
                                                        </a>
                                                    </router-link></span
                                                >
                                            </p>
                                            <p v-else class="no-mails text-primary">
                                                THE END...
                                            </p>
                                        </div>
                                        <!--end::Items-->
                                    </div>
                                </div>
                            </template>
                        </v-simple-table>
                        <p v-if="!loadFinalizedMails" class="no-mails text-primary">
                            Se incarca conversatiile...
                            <b-icon
                                icon="arrow-clockwise"
                                animation="spin-pulse"
                            ></b-icon>
                        </p>
                        <p
                            class="no-mails"
                            v-if="loadFinalizedMails && !finalizedMails.length"
                        >
                            Nu sunt mailuri disponibile.
                        </p>
                    </template>
                    <template v-slot:buttons>
                        <b-button-toolbar
                            class=""
                            aria-label="Toolbar with button groups and dropdown menu"
                        >
                            <b-button-group class="mx-1 btn-group">
                                <b-button
                                    disabled
                                    style="margin-right: 0.25rem !important"
                                >
                                    {{ totalFinalizedMails }}
                                    <b-icon
                                        v-if="!loadFinalizedMails"
                                        icon="arrow-clockwise"
                                        animation="spin-pulse"
                                    ></b-icon>
                                </b-button>
                            </b-button-group>
                        </b-button-toolbar>
                    </template>
                </KTCodePreview>
            </div>
        </div>
    </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import CompletedItem from "@/view/pages/dashboard/list-items-comp/CompletedItem.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {};
    },
    components: {
        KTCodePreview,
        CompletedItem,
    },
    computed: {
        ...mapActions("mails", ["getFinalizedMails"]),
        ...mapGetters("mails", ["finalizedMails"]),
        ...mapGetters("mails", ["totalFinalizedMails"]),
        ...mapGetters("mails", ["loadFinalizedMails"]),
    },
    methods: {
        formatDate(date_created) {
            const date = new Date(date_created);
            return `${("0" + date.getDate()).slice(-2)}-${(
                "0" +
                (date.getMonth() + 1)
            ).slice(-2)} ${date.getFullYear()}`;
        },
    },
    created() {
        // this.getFinalizedMails;
    },
    mounted() {},
};
</script>

<style scoped>
.closed-mail {
    background-color: #26c281;
}
</style>
