var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-pase","title":"Paseaza mail","hide-footer":""}},[_c('v-form',{ref:"pasareForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p',[_vm._v("Selecteaza agentul si departamentul la care doresti sa pasezi mail-ul.")]),_c('v-row',[_c('v-col',{staticClass:"v-application",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.eachDepartments,"rules":[(v) => !!v || 'Campul este obligatoriu'],"dense":"","outlined":"","required":""},on:{"change":function($event){return _vm.getPasareDeparts()}},model:{value:(_vm.departId),callback:function ($$v) {_vm.departId=$$v},expression:"departId"}})],1),(_vm.showSelectUser)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"disabled":!_vm.showSelectUser,"items":_vm.departmentUsers,"menu-props":"auto","dense":"","outlined":""},on:{"change":function($event){return _vm.getUsers()}},scopedSlots:_vm._u([{key:"item",fn:function({ active, item, attrs, on }){return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.text))]),_c('v-spacer'),(item.value)?_c('span',{staticClass:"label label-rounded label-light-success font-weight-bolder ml-1",class:[
                                                item.status === 'active'
                                                    ? 'label-light-success'
                                                    : '',
                                                item.status === 'vacation'
                                                    ? 'label-light-danger'
                                                    : '',
                                                item.status === 'busy'
                                                    ? 'label-light-warning'
                                                    : '',
                                            ]},[_vm._v(_vm._s(item.count)+" ")]):_vm._e()],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,4118716040),model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Grad de importanta")]),_c('v-select',{attrs:{"items":_vm.tags,"menu-props":"auto","dense":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function({ active, item, attrs, on }){return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(" "+_vm._s(item.text.charAt(0).toUpperCase() + item.text.slice(1))+" ")]),_c('v-spacer'),(item.value)?_c('i',{staticClass:"flaticon-add-label-button",style:({ color: _vm.colors[item.color] })}):_vm._e()],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"selection",fn:function({ item, on }){return [_c('v-container',{staticClass:"p-0"},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-around"}},[_c('span',[_vm._v(" "+_vm._s(item.text.charAt(0).toUpperCase() + item.text.slice(1))+" ")]),_c('v-spacer'),(item.value)?_c('i',{staticClass:"flaticon-add-label-button",style:({ color: _vm.colors[item.color] })}):_vm._e()],1)],1)]}}]),model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',[_c('v-btn',{staticClass:"spam-btn btn-paseaza",attrs:{"type":"submit"},on:{"click":_vm.mailAssignee}},[_vm._v("PASEAZA")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }