<template>
  <v-menu v-if="showTag" bottom offset-y>
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip top>
        <template #activator="{ on: onTooltip }">
          <i
            v-on="{ ...onMenu, ...onTooltip }"
            class="flaticon-add-label-button"
            :class="size"
            :style="{ color: tagColor }"
          ></i>
        </template>

        <span>{{ tagName }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, i) in configs.tags"
        :key="i"
        dense
        @click="changeTag(item)"
      >
        <i class="flaticon-add-label-button" :style="{ color: colors[item.color] }"></i>
        <!-- <v-list-item-title>{{ item.title }}</v-list-item-title> -->
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ["tag", "id", "size"],
  data() {
    return {
      // tagColor: "#ddd",
      tagName: "Alege grad importanta",
      colors: [
        "#97a4b0",
        "#52ad67",
        "#349DE9",
        "#F68F33",
        "#8c75bd",
        "#F0554F",
        "#9e6937",
      ],
      showTag: false,
    };
  },
  components: {},
  // watch: {
  //   tag() {
  //     console.log("test");
  //   },
  // },
  computed: {
    configs() {
      return this.$store.getters["mails/config"];
    },
    tagColor() {
      return this.tag?.length > 0 ? this.colors[this.tag[0].color] : "#ddd";
    },
  },
  methods: {
    changeTag(item) {
      // console.log(item);
      let payload = {
        conversation_id: this.id,
        color: item.color,
        tags: {
          tags: [item.name],
        },
      };
      // console.log(payload);
      this.$store.dispatch("mails/changeTag", payload).then(() => {
        this.tagColor = this.colors[item.color];
        this.tagName = item.name;
      });
    },
  },
  mounted() {
    if (this.tag?.length > 0) {
      // console.log(this.tag);
      // let tag = this.configs.tags.find((tag) => tag.id === this.tag[0].id);
      // console.log(tag);
      // this.tagColor = this.colors[this.tag[0].color];
      this.tagName = this.tag[0].name;
    }
    this.showTag = true;
  },
};
</script>
