<template>
    <div>
        <div class="row">
            <div class="col-md-12 panding-mails">
                <KTCodePreview
                    class="pending-mails"
                    v-bind:title="'Mail-uri in asteptare'"
                >
                    <template v-slot:preview>
                        <v-simple-table fixed-header v-if="pendingMails.length > 0">
                            <template v-slot:default>
                                <div class="card card-custom card-stretch">
                                    <div class="card-body table-responsive px-0">
                                        <!--begin::Items-->
                                        <div
                                            class="list mail-table list-hover min-w-500px"
                                        >
                                            <PendingItem
                                                v-for="(mail, i) in pendingMails"
                                                :key="i"
                                                :mail="mail"
                                            >
                                            </PendingItem>
                                        </div>
                                        <!--end::Items-->
                                    </div>
                                </div>
                            </template>
                        </v-simple-table>
                        <p v-if="!loadPendingMails" class="no-mails text-primary">
                            Se incarca conversatiile...
                            <b-icon
                                icon="arrow-clockwise"
                                animation="spin-pulse"
                            ></b-icon>
                        </p>
                        <p
                            class="no-mails"
                            v-if="loadPendingMails && !pendingMails.length"
                        >
                            Nu sunt mailuri disponibile.
                        </p>
                    </template>
                    <template v-slot:buttons>
                        <b-button-toolbar class="">
                            <b-button-group class="mx-1 btn-group">
                                <b-button
                                    disabled
                                    style="margin-right: 0.25rem !important"
                                >
                                    {{ totalPendingMails }}

                                    <b-icon
                                        v-if="!loadPendingMails"
                                        icon="arrow-clockwise"
                                        animation="spin-pulse"
                                    ></b-icon>
                                </b-button>
                            </b-button-group>
                        </b-button-toolbar>
                    </template>
                </KTCodePreview>
            </div>
        </div>
    </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import PendingItem from "@/view/pages/dashboard/list-items-comp/PendingItem.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapActions("mails", ["getPendingMails"]),
        ...mapGetters("mails", ["pendingMails"]),
        ...mapGetters("mails", ["totalPendingMails"]),
        ...mapGetters("mails", ["filterU"]),
        ...mapGetters("mails", ["loadPendingMails"]),
    },
    methods: {},
    created() {
        // this.getPendingMails;
    },
    components: {
        KTCodePreview,
        PendingItem,
    },
    mounted() {},
};
</script>
