<template>
    <v-dialog v-model="show" max-width="400">
        <v-card class="spam-dialog">
            <v-card-title>
                Sunteti sigur ca doriti sa finalizati aceste conversatii?
            </v-card-title>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="show = false"> Renunta </v-btn>

                <v-btn color="green darken-1" text @click="closeMails()">
                    De acord
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: Boolean,
        selectedMails: Array,
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        closeMails() {
            let payload = {
                conversations_ids: this.selectedMails,
                status: "closed",
            };
            //dispatch
            this.$store.dispatch("mails/changeStatus", payload).then(() => {
                this.show = false;
            });
        },
    },
};
</script>
