<template>
    <div
        class="subheader py-2 py-lg-4 subheader-solid"
        v-bind:class="subheaderClasses"
        id="kt_subheader"
    >
        <Departments></Departments>
    </div>
</template>

<style lang="scss">
.custom-v-dropdown {
    &.dropdown-toggle {
        padding: 0;
        &:hover {
            text-decoration: none;
        }

        &.dropdown-toggle-no-caret {
            &:after {
                content: none;
            }
        }
    }

    &.dropdown-menu {
        margin: 0;
        padding: 0;
        outline: none;
        .b-dropdown-text {
            padding: 0;
        }
    }
}
</style>

<script>
import { mapGetters } from "vuex";
import Departments from "@/view/pages/dashboard/Departments.vue";

export default {
    name: "KTSubheader",
    components: {
        Departments,
    },
    props: {
        breadcrumbs: Array,
        title: String,
    },
    computed: {
        ...mapGetters(["layoutConfig"]),
        ...mapGetters(["user"]),
        /**
         * Check if subheader width is fluid
         */
        widthFluid() {
            return this.layoutConfig("subheader.width") === "fluid";
        },

        subheaderClasses() {
            const classes = [];
            const style = this.layoutConfig("subheader.style");
            if (style) {
                classes.push(style);

                if (style === "solid") {
                    classes.push("bg-white");
                }

                if (this.layoutConfig("subheader.fixed")) {
                    classes.push("border-top");
                }
            }
            return classes.join(" ");
        },
    },

    async created() {
        const route = Object.assign({}, this.$router.history.current);
        await this.$store.dispatch("departments/getDepartments").then(() => {
            let payload = {};
            if (this.$router.history.current.params.department) {
                this.$store.dispatch(
                    "departments/getDepartment",
                    +this.$router.history.current.params.department
                );
                payload.mailboxId = +this.$router.history.current.params.department;
            } else {
                const departments = this.$store.getters["departments/departments"];
                let departs_ids = "";
                for (const depart in departments) {
                    departs_ids =
                        departs_ids + (depart > 0 ? "," : "") + departments[depart].id;
                }
                payload.mailboxId = departs_ids;
            }

            if (route.params.user) {
                payload.assignedTo = +this.$router.history.current.params.user;
            } else if (this.user.id && this.user.role !== "admin") {
                payload.assignedTo = this.user.id;
                this.$store.dispatch("mails/getUserName", this.user.id);
                this.$router.push({ name: "user", params: { user: this.user.id } });
            }

            this.$store.dispatch("mails/getConversations", payload);

            // if (this.$router.history.current.params.department) {
            //   this.$store.dispatch(
            //     "departments/getDepartment",
            //     +this.$router.history.current.params.department
            //   );
            // }
        });
    },

    destroyed() {
        this.$store.dispatch("mails/clearConversations");
    },
};
</script>
