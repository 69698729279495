<template>
    <b-modal id="modal-pase" title="Paseaza mail" hide-footer>
        <v-form ref="pasareForm" v-model="valid" lazy-validation>
            <p>Selecteaza agentul si departamentul la care doresti sa pasezi mail-ul.</p>
            <v-row>
                <v-col cols="12" class="v-application">
                    <v-select
                        v-model="departId"
                        :items="eachDepartments"
                        @change="getPasareDeparts()"
                        :rules="[(v) => !!v || 'Campul este obligatoriu']"
                        dense
                        outlined
                        required
                    ></v-select>
                </v-col>
                <v-col v-if="showSelectUser" cols="12">
                    <!-- <b-form-select
                  v-if="showSelectUser"
                  class="conv-drd2"
                  v-model="selects.selected2"
                  :options="departmentUsers"
                ></b-form-select> -->
                    <v-select
                        :disabled="!showSelectUser"
                        v-model="userId"
                        :items="departmentUsers"
                        @change="getUsers()"
                        menu-props="auto"
                        dense
                        outlined
                    >
                        <template v-slot:item="{ active, item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-row no-gutters align="center">
                                            <span>{{ item.text }}</span>

                                            <v-spacer></v-spacer>
                                            <span
                                                v-if="item.value"
                                                class="label label-rounded label-light-success font-weight-bolder ml-1"
                                                :class="[
                                                    item.status === 'active'
                                                        ? 'label-light-success'
                                                        : '',
                                                    item.status === 'vacation'
                                                        ? 'label-light-danger'
                                                        : '',
                                                    item.status === 'busy'
                                                        ? 'label-light-warning'
                                                        : '',
                                                ]"
                                                >{{ item.count }}
                                            </span>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template></v-select
                    >
                </v-col>
                <v-col cols="12">
                    <p>Grad de importanta</p>
                    <v-select
                        v-model="tag"
                        :items="tags"
                        menu-props="auto"
                        dense
                        outlined
                    >
                        <template v-slot:item="{ active, item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-row no-gutters align="center">
                                            <span>
                                                {{
                                                    item.text.charAt(0).toUpperCase() +
                                                    item.text.slice(1)
                                                }}
                                            </span>
                                            <v-spacer></v-spacer>
                                            <i
                                                v-if="item.value"
                                                class="flaticon-add-label-button"
                                                :style="{ color: colors[item.color] }"
                                            ></i>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item, on }">
                            <v-container class="p-0">
                                <v-row no-gutters align="center" justify="space-around">
                                    <span>
                                        {{
                                            item.text.charAt(0).toUpperCase() +
                                            item.text.slice(1)
                                        }}
                                    </span>
                                    <v-spacer></v-spacer>
                                    <i
                                        v-if="item.value"
                                        class="flaticon-add-label-button"
                                        :style="{ color: colors[item.color] }"
                                    ></i>
                                </v-row>
                            </v-container>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="4">
                    <div>
                        <v-btn
                            type="submit"
                            @click="mailAssignee"
                            class="spam-btn btn-paseaza"
                            >PASEAZA</v-btn
                        >
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            valid: true,
            showSelectUser: false,
            departId: null,
            userId: null,
            tag: null,
            selects: {
                options: [{ value: null, text: "Alege un departament*", disabled: true }],
                options2: [{ value: null, text: "Niciun agent selectat" }],
            },
            colors: [
                "#97a4b0",
                "#52ad67",
                "#349DE9",
                "#F68F33",
                "#8c75bd",
                "#F0554F",
                "#9e6937",
            ],
        };
    },
    computed: {
        ...mapGetters("departments", ["eachDepartments"]),
        ...mapGetters("departments", ["departmentUsers"]),
        tags() {
            const tags = this.$store.getters["mails/config"].tags;
            let newTags = [
                { value: null, text: "Alege grad importanta", disabled: true },
            ];
            tags.map((elem) => {
                newTags.push({ value: elem.name, text: elem.name, color: elem.color });
            });
            return newTags;
        },
    },
    methods: {
        ...mapActions("mails", ["closeModal"]),
        ...mapActions("mails", ["assigneeMail"]),
        modalClose() {
            this.closeModal;
        },
        mailAssignee(evt) {
            evt.preventDefault();
            if (this.$refs.pasareForm.validate()) {
                var payload = { departId: this.departId, userId: this.userId };
                if (this.tag) {
                    payload.tags = [this.tag];
                }
                // console.log(payload);
                // console.log(this.tag);
                this.assigneeMail(payload).then(() => {
                    this.$bvModal.hide("modal-pase");
                    this.departId = null;
                    this.userId = null;
                });
            }
        },
        getPasareDeparts() {
            let departId = this.departId;
            let userId = null;
            if (departId) {
                this.showSelectUser = true;
            }

            this.$store.dispatch("mails/asigneeDepUser", { departId, userId });
            this.$store.dispatch("departments/getPasareUsers", departId);
        },
        getUsers() {
            let departId = this.departId;
            if (departId) {
                this.showSelectUser = true;
            }
            let userId = this.userId;
            this.$store.dispatch("mails/asigneeDepUser", { departId, userId });
        },
    },
    mounted() {
        // console.log(this.eachDepartments);
    },
    updated() {
        // console.log(this.eachDepartments);
    },
    created() {
        this.$store.dispatch("departments/getPasareDepartments");
    },
};
</script>

<style scoped lang="scss">
::v-deep .v-application--wrap {
    min-height: fit-content;
}
</style>
